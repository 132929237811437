<template>
  <main>
    <CModalExtended
      :title="$t('label.manualsHandlingCommodity')"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="pa-3"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <dataTableExtended
     class="align-center-row-datatable"
        size="lg"
        :fields="fields"
        :items="computedComodityDocs"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingTable"
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #options="{ item }">
          <td class="center-cell">
            <CButton
              square
              color="watch"
              size="sm"
              v-c-tooltip="{
                content: $t('label.toView')+' '+$t('label.commodity'),
                placement: 'top-end',
              }"
              @click="openDoc(item.enlaceDocs, item.nameDoc)"
            >
              <CIcon name="eye" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <template #footer>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{ $t('label.exit') }}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import { DateFormater,tableTextTranslatedHelpers } from '@/_helpers/funciones';


function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    itemsCommodityDocs: [],
    
  };
}

function fields(){
  return [
      { label: '#', key: 'Nro', filter:false, _style: 'width:3%; text-align:center', },
      {
        label: this.$t('label.handlingManuals'), key: 'nameDoc',  _style:'width:67%;',  _classes:"center-cell text-center" },
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;',  _classes:"center-cell text-center" },
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center"},
      {  key: 'options',
        label: '', 
      _style: 'min-width: 50px; width:5%', 
      sorter: false, 
      filter: false,
      },
    ];

}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.showModal = false;
  this.$emit('updated-modal', event);
}

function tableText(){
  return tableTextTranslatedHelpers(this)
}

function openDoc(url, nameDoc) {
  let cuteUrl = url.split('.');
  let docExtension = cuteUrl[cuteUrl.length - 1];

  if (docExtension === 'pdf') {
    let win = window.open(url, '_blank');
    win.focus();
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nameDoc);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedComodityDocs(comodityId) {
  //this.loadingOverlay = true;
  this.loadingTable = true;

  this.$http
    .get(`CommodityDoc-list?CommodityId=${comodityId}`)
    .then((response) => (this.itemsCommodityDocs = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion computed para formatear los datos de la tabla ###
function computedComodityDocs() {
  if (this.itemsCommodityDocs.length > 0) {
    return this.itemsCommodityDocs.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        nameDoc: item.RouteCommodityDoc
          ? `${item.CommodityName} - ${item.RouteCommodityDoc.replace(
              'Public/Commodity/',
              ''
            )}`
          : item.CommodityName,
        enlaceDocs: item.RouteCommodityDoc
          ? `${this.$store.getters["connection/getBase"]}${item.RouteCommodityDoc.replace(
              'Public/',
              ''
            )}`
          : '',
      };
    });
  }
}

export default {
  name: 'manualModalMercancia',
  data,
  props: {
    modal: null,
    commodityId: String,
  },
  methods: {
    closeModal,
    mountedComodityDocs,
    openDoc,
  },
  computed: {
    computedComodityDocs,
    tableText,
    fields,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
      if (val) {
        this.mountedComodityDocs(this.commodityId);
      }
    },
  },
};
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
