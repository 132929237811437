<template>
  <div class="pa-2 mt-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CCol sm="12" class="d-flex justify-content-end">
      <CButton
        square
        color="add"
        class="d-flex align-items-center"
        @click="toggleShowModalCreateState"
      >
        <CIcon name="cil-playlist-add" />
        <span class="ml-1">{{$t('label.packagingRelationship')}}</span>
      </CButton>
    </CCol>
    <CCol sm="12" lg="4" class="justify-content-start">
      <CSelect
        :label="$t('label.cargoState')"
        addLabelClasses="text-right"
        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-7'}"
        :options="computedBulkCargoSelect"
        @change="stateSelectedFilter($event)"
        :value="valueStateSelected"
      />
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        size="lg"
        :fields="fields"
        :items="computedBulkCargoCommodity"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
        
      >
        <!-- <template #over-table>
          <div class="row justify-content-start" style="margin-top: -2.8rem">
            <div
              class="col-auto ml-2 mt-2 pr-0 mr-0"
              style="margin-left: 16rem !important"
            >
              {{$t('label.cargoState')}} :
            </div>
            <div class="col-3">
              <CSelect
                :options="computedBulkCargoSelect"
                @change="stateSelectedFilter($event)"
                :value="valueStateSelected"
              />
            </div>
          </div>
        </template> -->
        <template #Status="{ item }">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #icon="{ item }">
          <td class="center-cell">
            <c-img-extended
              :src="item.icon"
              :key="item.Nro"
              :error-options="{ width: 40, height: 40, fontSize: 8 }"
              thumbnail
              class="img-logo"
              :width="imageWidth"
              :height="imageHeight"
            />
          </td>
        </template>

        <template #options="{ item }">
          <td>
              <CButton
                color="watch"
                class="mr-1"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.see')+' '+$t('label.commodityHandlingDocumentation'),
                  placement: 'left',
                }"
                @click="openModalManual(item.CommodityId)"
              >
                <CIcon name="eye" />
              </CButton>
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.relationshipBulkCargoCommodity'),
                  placement: 'left',
                }"
                @click="toggleShowModalEditBulkCargo(item.TpCargoId)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
    

    <modalMercancia
      :modal.sync="showModal"
      @updated-modal="updateShowModalCreate"
      :editModal="editModal"
      @refresh-data-table="mountedBulkCargoCommodity"
      :idBulkCargoSelected="idBulkCargoSelected"
      :title="titleModal"
    />

    <manualModalMercancia
      :modal.sync="showModalManual"
      @updated-modal="closeModalManual"
      :commodityId="commodityId"
    />
  </div>
</template>
<script>
import { DateFormater, GenerateImgPlaceholder } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import { TableIndexMixin } from '@/_mixins/carga-granel';
import CImgExtended from '@/components/CImgExtended';
import modalMercancia from './modal-mercancia';
import manualModalMercancia from './modal-manual-mercancia';

function data() {
  return {
    itemsBulkCommodity: [],
    imageWidth: 45,
    imageHeight: 45,
    showModal: false,
    editModal: false,
    itemsBulkCargoSelect: [],
    temporalItemsBulkCommodity: [],
    idBulkCargoSelected: '',
    showModalManual: false,
    commodityId: '',
    valueStateSelected: 'N/A',
    titleModal: '',
    Loading: false
  };
}

function fields(){
  return  [
      { label: '#', key: 'Nro', filter:false, _style: 'width:3%; text-align:center', },
      { key: 'TpCargoName' , label: this.$t('label.cargoState'), _style:'width:28%;', _classes: 'text-uppercase text-center' },
      { key: 'CommodityNameEs',  label:`${this.$t('label.commodity')} (ES)`, _style:'width:25%;',  _classes: 'text-uppercase text-center' },
      { key: 'CommodityNameEn',  label:`${this.$t('label.commodity')} (EN)`, _style:'width:25%;', _classes: 'text-uppercase text-center'},
      {
        label: 'ICON',
        key: 'icon',
        _style: 'text-align:center',
        _classes: 'text-center',
        filter: false,
      },
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;' },
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;'},
      { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;'},
      {  key: 'options',
        label: '', 
      _style: 'min-width: 100px; width:8%', 
      sorter: false, 
      filter: false,
      },
    ];
}

//## Activa el modal para crear nuevo Estado
function toggleShowModalCreateState() {
  this.showModal = true;
  this.titleModal = this.$t('label.relationshipBulkCargoCommodity');

}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedBulkCargoCommodity() {
  //this.loadingOverlay = true;
  this.valueStateSelected = 'N/A';
  this.Loading = true;

  await this.$http
    .get('BulkCargoCommodity-list?TpCargoId=')
    .then((response) => {
      this.itemsBulkCommodity = response.data.data;
      this.temporalItemsBulkCommodity = response.data.data;
    })
    .finally(() => {
      this.Loading = false;
    })
    .catch((e) => {
      this.Loading = false;
      this.notifyError({text: e});
    });
  await this.mountedBulkCargo();
}

//### Funcion computed para formatear los datos de la tabla ###
function computedBulkCargoCommodity() {
  if (this.itemsBulkCommodity.length > 0) {
    return this.itemsBulkCommodity.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        icon: item.CommodityRoute
          ? `${this.$store.getters["connection/getBase"]}${item.CommodityRoute}`
          : `${this.$store.getters["connection/getBase"]}${item.CommodityRoute}`,
      };
    });
  }
}

//### ACtualiza el estado una vez cerrado el modal
function updateShowModalCreate(event) {
  this.showModal = false;
  this.editModal = false;
  this.idBulkCargoSelected = '';
}

function closeModalManual() {
  this.showModalManual = false;
  this.commodityId = '';
}

function openModalManual(commodityId) {
  this.commodityId = commodityId;
  this.showModalManual = true;
}

function stateSelectedFilter(event) {
  this.valueStateSelected = event.target.value;
  this.itemsBulkCommodity = this.temporalItemsBulkCommodity;
  if (event.target.value === 'N/A') {
    this.itemsBulkCommodity = this.temporalItemsBulkCommodity;
  } else {
    this.itemsBulkCommodity = this.itemsBulkCommodity.filter(
      (elelment) => elelment.TpCargoId === event.target.value
    );
  }
}

function toggleShowModalEditBulkCargo(id) {
  this.idBulkCargoSelected = id;
  this.showModal = true;
  this.editModal = true;
  this.titleModal = this.$t('label.edit')+' '+this.$t('label.relationshipBulkCargoCommodity');

}

export default {
  name: 'mercanciaCgaGranel',
  data,
  props:{Tab: Number},
  mixins: [
    GeneralMixin,
    TableIndexMixin,
  ],
  components: {
    CImgExtended,
    modalMercancia,
    manualModalMercancia,
  },
  methods: {
    toggleShowModalCreateState,
    updateShowModalCreate,
    stateSelectedFilter,
    mountedBulkCargoCommodity,
    toggleShowModalEditBulkCargo,
    closeModalManual,
    openModalManual,
  },
  computed: {
    computedBulkCargoCommodity,
    fields,
  },
  watch:{
    Tab: async function (val){
      if(val == 2){
        await this.mountedBulkCargoCommodity()
      }
    }
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
