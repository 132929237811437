var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2 mt-3"},[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleShowModalCreateState}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.packagingRelationship')))])],1)],1),_c('CCol',{staticClass:"justify-content-start",attrs:{"sm":"12","lg":"4"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.cargoState'),"addLabelClasses":"text-right","horizontal":{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-7'},"options":_vm.computedBulkCargoSelect,"value":_vm.valueStateSelected},on:{"change":function($event){return _vm.stateSelectedFilter($event)}}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedBulkCargoCommodity,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"icon",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('c-img-extended',{key:item.Nro,staticClass:"img-logo",attrs:{"src":item.icon,"error-options":{ width: 40, height: 40, fontSize: 8 },"thumbnail":"","width":_vm.imageWidth,"height":_vm.imageHeight}})],1)]}},{key:"options",fn:function({ item }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.see')+' '+_vm.$t('label.commodityHandlingDocumentation'),
                placement: 'left',
              }),expression:"{\n                content: $t('label.see')+' '+$t('label.commodityHandlingDocumentation'),\n                placement: 'left',\n              }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.openModalManual(item.CommodityId)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.edit')+' '+_vm.$t('label.relationshipBulkCargoCommodity'),
                placement: 'left',
              }),expression:"{\n                content: $t('label.edit')+' '+$t('label.relationshipBulkCargoCommodity'),\n                placement: 'left',\n              }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditBulkCargo(item.TpCargoId)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1),_c('modalMercancia',{attrs:{"modal":_vm.showModal,"editModal":_vm.editModal,"idBulkCargoSelected":_vm.idBulkCargoSelected,"title":_vm.titleModal},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateShowModalCreate,"refresh-data-table":_vm.mountedBulkCargoCommodity}}),_c('manualModalMercancia',{attrs:{"modal":_vm.showModalManual,"commodityId":_vm.commodityId},on:{"update:modal":function($event){_vm.showModalManual=$event},"updated-modal":_vm.closeModalManual}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }